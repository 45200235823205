import { Component, OnInit } from "@angular/core";
declare var $: any;

@Component({
  selector: "app-pgm5s",
  templateUrl: "./pgm5s.component.html",
  styleUrls: ["./pgm5s.component.scss"]
})
export class Pgm5sComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    var pageCarousel = $(".owl-carousel");

    if (pageCarousel.length) {
      $(".owl-carousel")
        .not("#thumbnails")
        .each(function() {
          /* Max items counting */
          var max_items = $(this).data("max-items");
          var tablet_items = max_items;
          if (max_items > 1) {
            tablet_items = max_items - 1;
          }
          var mobile_items = 1;

          var autoplay_carousel = $(this).data("autoplay");

          var center_carousel = $(this).data("center");

          var item_margin = $(this).data("item-margin");

          /* Install Owl Carousel */
          ($(this) as any).owlCarousel({
            smartSpeed: 450,
            nav: true,
            loop: true,
            autoplay: autoplay_carousel,
            center: center_carousel,
            autoplayTimeout: 3000,
            navText: false,
            margin: item_margin,
            lazyLoad: true,
            // rtl: $.mad_core.SUPPORT.ISRTL ? true : false,
            responsiveClass: true,
            responsive: {
              0: {
                items: mobile_items
              },
              481: {
                items: tablet_items
              },
              992: {
                items: max_items
              }
            }
          });
        });

      $(".custom-owl-prev").on("click", function() {
        $(".owl-carousel").trigger("prev.owl.carousel");

        return false;
      });

      $(".custom-owl-next").on("click", function() {
        $(".owl-carousel").trigger("next.owl.carousel");

        return false;
      });

      if ($("#thumbnails").length) {
        $("#thumbnails").each(function() {
          ($(this) as any).owlCarousel({
            items: 4,
            URLhashListener: false,
            navSpeed: 800,
            margin: 10,
            dots: false,
            nav: true,
            loop: true,
            // rtl: $.mad_core.SUPPORT.ISRTL ? true : false,
            navText: false,
            responsive: {
              0: {
                items: 2
              },
              481: {
                items: 4
              }
            }
          });
        });
      }
    }
  }
}
