import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inquire',
  templateUrl: './inquire.component.html',
  styleUrls: ['./inquire.component.scss']
})
export class InquireComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
