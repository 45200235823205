import { Component, OnInit } from "@angular/core";
import * as eventsData from "../../../assets/json/home-upcoming-events.json";
import * as reviewData from "../../../assets/json/what-parents-say.json";
// import * as $ from "jquery";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  events: any = (eventsData as any).default;
  reviews: any = (reviewData as any).default;

  constructor() {}

  ngOnInit() {}
}
