import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { AboutComponent } from "./pages/about/about.component";
import { ProgramsComponent } from "./programs/programs.component";
import { GalaryComponent } from "./knowmore/galary/galary.component";
import { EventsComponent } from "./knowmore/events/events.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { Pgmp2sComponent } from "./programs/pgmp2s/pgmp2s.component";
import { Pgm2sComponent } from "./programs/pgm2s/pgm2s.component";
import { Pgm3sComponent } from "./programs/pgm3s/pgm3s.component";
import { Pgm4sComponent } from "./programs/pgm4s/pgm4s.component";
import { Pgm5sComponent } from "./programs/pgm5s/pgm5s.component";
import { PgmDaycareComponent } from "./programs/pgm-daycare/pgm-daycare.component";
import { PgmAfterSchoolComponent } from "./programs/pgm-after-school/pgm-after-school.component";
import { BranchComponent } from "./branch/branch.component";
import { AkshyanagarComponent } from "./branch/india/karnataka/bangalore/akshyanagar/akshyanagar.component";
import { NrilayoutComponent } from "./branch/india/karnataka/bangalore/nrilayout/nrilayout.component";
import { VaranasiComponent } from "./branch/india/karnataka/bangalore/varanasi/varanasi.component";
import { WccComponent } from "./branch/india/tamilnadu/nagercoil/wcc/wcc.component";
import { CurriculumComponent } from "./knowmore/curriculum/curriculum.component";
import { EmploymentComponent } from "./knowmore/employment/employment.component";
import { FunclubComponent } from "./knowmore/funclub/funclub.component";
import { TestimonialsComponent } from "./knowmore/testimonials/testimonials.component";
import { KnowmoreComponent } from "./knowmore/knowmore.component";
import { InquireComponent } from "./pages/inquire/inquire.component";
import { EnrolnowComponent } from "./pages/enrolnow/enrolnow.component";
import { LoginComponent } from "./pages/login/login.component";
import { EligibilityComponent } from "./pages/eligibility/eligibility.component";
import { SummercampComponent } from "./knowmore/events/summercamp/summercamp.component";
import { BanaswadiComponent } from './branch/india/karnataka/bangalore/banaswadi/banaswadi.component';
import { RamavarmapuramComponent } from './branch/india/tamilnadu/nagercoil/ramavarmapuram/ramavarmapuram.component';

const routes: Routes = [
  { path: "", redirectTo: "/", pathMatch: "full" },
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "programs", component: ProgramsComponent },
  { path: "programe/pre-2s", component: Pgmp2sComponent },
  { path: "programe/2s", component: Pgm2sComponent },
  { path: "programe/3s", component: Pgm3sComponent },
  { path: "programe/4s", component: Pgm4sComponent },
  { path: "programe/5s", component: Pgm5sComponent },
  { path: "programe/daycare", component: PgmDaycareComponent },
  { path: "programe/after-school", component: PgmAfterSchoolComponent },
  { path: "branches", component: BranchComponent },
  {
    path: "branch/india/karnataka/bangalore/akshya-nagar",
    component: AkshyanagarComponent
  },
  {
    path: "branch/india/karnataka/bangalore/nri-layout",
    component: NrilayoutComponent
  },
  {
    path: "branch/india/karnataka/bangalore/varanasi",
    component: VaranasiComponent
  },
  { path: "branch/india/tamilnadu/nagercoil/wcc", component: WccComponent },
  { path: "branch/india/karnataka/bangalore/banaswadi", component: BanaswadiComponent },
  { path: "branch/india/tamilnadu/nagercoil/ramavarmapuram", component: RamavarmapuramComponent },
  { path: "knowmore", component: KnowmoreComponent },
  { path: "knowmore/curriculum", component: CurriculumComponent },
  { path: "knowmore/employment", component: EmploymentComponent },
  { path: "knowmore/fun-club", component: FunclubComponent },
  { path: "knowmore/testimonials", component: TestimonialsComponent },
  { path: "knowmore/gallery", component: GalaryComponent },
  { path: "knowmore/events", component: EventsComponent },
  { path: "contact", component: ContactComponent },
  { path: "inquire", component: InquireComponent },
  { path: "enrol-now", component: EnrolnowComponent },
  { path: "login", component: LoginComponent },
  { path: "knowmore/eligibility", component: EligibilityComponent },
  { path: "knowmore/event/summer-camp", component: SummercampComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
