import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userapp',
  templateUrl: './userapp.component.html',
  styleUrls: ['./userapp.component.sass']
})
export class UserappComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
