import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rightaside',
  templateUrl: './rightaside.component.html',
  styleUrls: ['./rightaside.component.scss']
})
export class RightasideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
