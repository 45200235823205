import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs";
declare var $: JQueryStatic;

@Component({
  selector: "app-employment",
  templateUrl: "./employment.component.html",
  styleUrls: ["./employment.component.scss"]
})
export class EmploymentComponent implements OnInit {
  branchs = [
    "NRI Layout, Bangalore",
    "Akshaya Nagar, Bangalore",
    "Varanasi, Bangalore",
    "WCC, Nagercoil"
  ];
  experiances = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  applications = [
    "Center Lead",
    "Academic Teacher",
    "Caretaker",
    "Transport",
    "Security",
    "Hindi Teacher",
    "Dance Master"
  ];

  itemName = "";
  itemMobile = "";
  itemEmail = "";
  itemDOB = "";
  itemBranch = "";
  itemApplication = "";
  itemExperiance = "";
  itemCurrentSalary = "";
  itemExpectedSalary = "";
  itemMessage = "";
  itemWhen = new Date().toUTCString();
  items: Observable<any[]>;
  employmentForm: FormGroup;

  constructor(private fb: FormBuilder, private db: AngularFireDatabase) {
    this.items = db.list("littlebeesorgcontactform").valueChanges();
    // Passing in MD_Bootstrap form validation
    this.employmentForm = fb.group({
      contactFormName: ["", Validators.required],
      contactFormMobile: ["", Validators.required],
      contactFormEmail: ["", [Validators.required, Validators.email]],
      contactFormDoB: ["", Validators.required],
      contactFormBranch: ["", Validators.required],
      contactFormApplication: ["", Validators.required],
      contactFormExperiance: ["", Validators.required],
      contactFormCurrentSalary: ["", Validators.required],
      contactFormExpectedSalary: ["", Validators.required],
      contactFormMessage: ["", Validators.required]
    });
  }

  clearForm() {
    this.employmentForm.reset();
  }
  pushData(data) {
    var cf = $("#employment-form");
    cf.append('<div class="message-container"></div>');
    this.db
      .list("/employment")
      .push(data)
      .then(snap => {
        const key = snap.key;
        console.log(snap);
        console.log(key);
        this.clearForm();
        $(".message-container")
          .html(
            '<div class="alert-box success"><i class="icon-warning"></i><p> Your message has been sent successfully! </p></div>'
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function () {
            $("#employment-form .message-container").remove();
          });
      })
      .catch(err => {
        console.log("Something went wrong:", err.message);
        $(".message-container")
          .html(
            '<div class="alert-box error"><i class="icon-warning"></i><p>' +
            err.message +
            "</p></div>"
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function () {
            $("#employment-form .message-container").remove();
          });
      });
  }
  onSubmit() {
    const data = {
      itemName: this.itemName,
      itemMobile: this.itemMobile,
      itemEmail: this.itemEmail,
      itemDOB: this.itemDOB,
      itemBranch: this.itemBranch,
      itemApplication: this.itemApplication,
      itemExperiance: this.itemExperiance,
      itemCurrentSalary: this.itemCurrentSalary,
      itemExpectedSalary: this.itemExpectedSalary,
      itemMessage: this.itemMessage,
      itemWhen: new Date().toUTCString()
    };
    let checkEmptyFlag = true;
    const checkEmpty = obj => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") checkEmpty(obj[key]);
        // recurse
        else if (obj[key] === null || obj[key] === "") {
          checkEmptyFlag = false;
          return false;
        }
      });
    };
    checkEmpty(data);
    if (checkEmptyFlag) {
      this.pushData(data);
      console.log(checkEmptyFlag);
    } else {
      console.log(checkEmptyFlag);
      var cf = $("#employment-form");
      cf.append('<div class="message-container"></div>');
      $(".message-container")
        .html(
          '<div class="alert-box error"><i class="icon-warning"></i><p>Please fill out all fields</p></div>'
        )
        .delay(150)
        .slideDown(300)
        .delay(4000)
        .slideUp(300, function () {
          $("#employment-form .message-container").remove();
        });
    }
  }

  ngOnInit() { }
}
