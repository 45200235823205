import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banaswadi',
  templateUrl: './banaswadi.component.html',
  styleUrls: ['./banaswadi.component.sass']
})
export class BanaswadiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
