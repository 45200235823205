import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-eligibility",
  templateUrl: "./eligibility.component.html",
  styleUrls: ["./eligibility.component.scss"]
})
export class EligibilityComponent implements OnInit {
  students = {
    dob: ""
  };
  eligibilityMessage = "";
  constructor() { }

  title = "Eligibility Check";
  type = "PieChart";
  data = [];
  columnNames = ["Program", "Percentage"];
  options = {
    pieHole: 0.4,
    colors: ["#CC33FF", "#81D4FA", "#DAF7A6"],
    backgroundColor: "transparent",
    legend: { position: "bottom" }
  };
  width = 600;
  height = 400;

  checkDate() {
    const dateSendingToServer = new DatePipe("en-US").transform(
      this.students.dob,
      "MM/dd/yyyy"
    );
    this.eligibilityMessage = this.getAge(dateSendingToServer);
  }

  getAge(dateString: any) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    const yearNow = now.getFullYear();
    const monthNow = now.getMonth();
    const dateNow = now.getDate();

    const dob = new Date(
      dateString.substring(6, 10),
      dateString.substring(0, 2) - 1,
      dateString.substring(3, 5)
    );

    const yearDob = dob.getFullYear();
    const monthDob = dob.getMonth();
    const dateDob = dob.getDate();
    let age = <any>{};
    let ageString = "";
    let yearString = "";
    let monthString = "";
    let dayString = "";

    let yearAge = yearNow - yearDob;
    let monthAge;
    let dateAge;

    if (monthNow >= monthDob) {
      monthAge = monthNow - monthDob;
    } else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob) {
      dateAge = dateNow - dateDob;
    } else {
      monthAge--;
      dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge
    };

    if (age.years > 1) {
      yearString = " years";
    } else {
      yearString = " year";
    }
    if (age.months > 1) {
      monthString = " months";
    } else {
      monthString = " month";
    }
    if (age.days > 1) {
      dayString = " days";
    } else {
      dayString = " day";
    }

    if (age.years > 0 && age.months > 0 && age.days > 0) {
      ageString = `${age.years + yearString}, ${
        age.months
        }${monthString}, and ${age.days}${dayString} old.`;
    } else if (age.years === 0 && age.months === 0 && age.days > 0) {
      ageString = `Only ${age.days}${dayString} old!`;
    } else if (age.years > 0 && age.months === 0 && age.days === 0) {
      ageString = `${age.years + yearString} old. Happy Birthday!!`;
    } else if (age.years > 0 && age.months > 0 && age.days === 0) {
      ageString = `${age.years + yearString} and ${
        age.months
        }${monthString} old.`;
    } else if (age.years === 0 && age.months > 0 && age.days > 0) {
      ageString = `${age.months + monthString} and ${
        age.days
        }${dayString} old.`;
    } else if (age.years > 0 && age.months === 0 && age.days > 0) {
      ageString = `${age.years + yearString} and ${age.days}${dayString} old.`;
    } else if (age.years === 0 && age.months > 0 && age.days === 0) {
      ageString = `${age.months + monthString} old.`;
    } else {
      ageString = "Oops! Could not calculate age!";
    }

    const Yage = age.years;
    const Mage = age.months;
    const Dage = age.days;

    // tslint:disable-next-line:radix
    let addAge = "" + Yage + Mage;
    // tslint:disable-next-line:radix
    let newAge = Number.parseInt(addAge);
    console.log(newAge);

    if (newAge <= 15) {
      this.data = [
        ["Pre 2's", 9],
        ["2's", 1],
        ["3's", 0],
        ["4's", 0],
        ["5's", 0]
      ];
    } else if (newAge <= 25) {
      this.data = [
        ["Pre 2's", 0],
        ["2's", 8],
        ["3's", 2],
        ["4's", 0],
        ["5's", 0]
      ];
    } else if (newAge <= 35) {
      this.data = [
        ["Pre 2's", 0],
        ["2's", 1],
        ["3's", 8],
        ["4's", 1],
        ["5's", 0]
      ];
    } else if (newAge <= 45) {
      this.data = [
        ["Pre 2's", 0],
        ["2's", 0],
        ["3's", 1],
        ["4's", 8],
        ["5's", 1]
      ];
    } else {
      this.data = [
        ["Pre 2's", 0],
        ["2's", 0],
        ["3's", 0],
        ["4's", 1],
        ["5's", 9]
      ];
    }
    return ageString;
  }

  ngOnInit() {
    // console.log(this.getAge("09/09/1989"));
  }
}
