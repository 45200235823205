import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-rightnavbranch",
  templateUrl: "./rightnavbranch.component.html",
  styleUrls: ["./rightnavbranch.component.scss"]
})
export class RightnavbranchComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
