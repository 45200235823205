import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MDBBootstrapModule } from "angular-bootstrap-md";
import { environment } from "../environments/environment";
import { GoogleChartsModule } from "angular-google-charts";
import { NgxAudioPlayerModule } from "ngx-audio-player";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./common/header/header.component";
import { NavigationComponent } from "./common/navigation/navigation.component";
import { SliderComponent } from "./common/slider/slider.component";
import { FooterComponent } from "./common/footer/footer.component";
import { AboutComponent } from "./pages/about/about.component";
import { GalaryComponent } from "./knowmore/galary/galary.component";
import { EventsComponent } from "./knowmore/events/events.component";
import { TestimonialsComponent } from "./knowmore/testimonials/testimonials.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { ProgramsComponent } from "./programs/programs.component";
import { Pgm2sComponent } from "./programs/pgm2s/pgm2s.component";
import { Pgm3sComponent } from "./programs/pgm3s/pgm3s.component";
import { Pgm4sComponent } from "./programs/pgm4s/pgm4s.component";
import { Pgm5sComponent } from "./programs/pgm5s/pgm5s.component";
import { PgmDaycareComponent } from "./programs/pgm-daycare/pgm-daycare.component";
import { PgmAfterSchoolComponent } from "./programs/pgm-after-school/pgm-after-school.component";
import { HomeComponent } from "./pages/home/home.component";
import { Pgmp2sComponent } from "./programs/pgmp2s/pgmp2s.component";
import { NrilayoutComponent } from "./branch/india/karnataka/bangalore/nrilayout/nrilayout.component";
import { AkshyanagarComponent } from "./branch/india/karnataka/bangalore/akshyanagar/akshyanagar.component";
import { VaranasiComponent } from "./branch/india/karnataka/bangalore/varanasi/varanasi.component";
import { WccComponent } from "./branch/india/tamilnadu/nagercoil/wcc/wcc.component";
import { BranchComponent } from "./branch/branch.component";
import { CurriculumComponent } from "./knowmore/curriculum/curriculum.component";
import { EmploymentComponent } from "./knowmore/employment/employment.component";
import { FunclubComponent } from "./knowmore/funclub/funclub.component";
import { KnowmoreComponent } from "./knowmore/knowmore.component";
import { EnrolbannerComponent } from "./common/enrolbanner/enrolbanner.component";
import { InquireComponent } from "./pages/inquire/inquire.component";
import { EnrolnowComponent } from "./pages/enrolnow/enrolnow.component";
import { LoginComponent } from "./pages/login/login.component";
import { FacebooklikeComponent } from "./common/facebooklike/facebooklike.component";
import { EligibilityComponent } from "./pages/eligibility/eligibility.component";
import { RightasideComponent } from "./programs/rightaside/rightaside.component";
import { RightnavbranchComponent } from "./branch/rightnavbranch/rightnavbranch.component";
import { SummercampComponent } from "./knowmore/events/summercamp/summercamp.component";
import { UserappComponent } from "./userapp/userapp.component";
import { BanaswadiComponent } from './branch/india/karnataka/bangalore/banaswadi/banaswadi.component';
import { RamavarmapuramComponent } from './branch/india/tamilnadu/nagercoil/ramavarmapuram/ramavarmapuram.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    SliderComponent,
    FooterComponent,
    AboutComponent,
    GalaryComponent,
    EventsComponent,
    TestimonialsComponent,
    ContactComponent,
    ProgramsComponent,
    Pgm2sComponent,
    Pgm3sComponent,
    Pgm4sComponent,
    Pgm5sComponent,
    PgmDaycareComponent,
    PgmAfterSchoolComponent,
    HomeComponent,
    Pgmp2sComponent,
    NrilayoutComponent,
    AkshyanagarComponent,
    VaranasiComponent,
    WccComponent,
    BranchComponent,
    CurriculumComponent,
    EmploymentComponent,
    FunclubComponent,
    KnowmoreComponent,
    EnrolbannerComponent,
    InquireComponent,
    EnrolnowComponent,
    LoginComponent,
    FacebooklikeComponent,
    EligibilityComponent,
    RightasideComponent,
    RightnavbranchComponent,
    SummercampComponent,
    UserappComponent,
    BanaswadiComponent,
    RamavarmapuramComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    GoogleChartsModule.forRoot(),
    NgxAudioPlayerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
