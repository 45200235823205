import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs";
declare var $: JQueryStatic;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  userName = "";
  password = "";
  items: Observable<any[]>;
  loginForm: FormGroup;
  constructor(private fb: FormBuilder, private db: AngularFireDatabase) {
    this.items = db.list("littlebeesorgcontactform").valueChanges();
    this.loginForm = fb.group({
      loginFormUserName: ["", Validators.required],
      loginFormPassword: ["", Validators.required]
    });
  }

  clearForm() {
    this.loginForm.reset();
  }
  pushData(data) {
    var cf = $("#login-form");
    cf.append('<div class="message-container"></div>');
    this.db
      .list("/login")
      .push(data)
      .then(snap => {
        const key = snap.key;
        console.log(snap);
        console.log(key);
        this.clearForm();
        $(".message-container")
          .html(
            '<div class="alert-box error"><i class="icon-warning"></i><p> Invalid credential! Please try again </p></div>'
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function() {
            $("#login-form .message-container").remove();
          });
      })
      .catch(err => {
        console.log("Something went wrong:", err.message);
        $(".message-container")
          .html(
            '<div class="alert-box error"><i class="icon-warning"></i><p>' +
              err.message +
              "</p></div>"
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function() {
            $("#login-form .message-container").remove();
          });
      });
  }
  onSubmit() {
    const data = {
      userName: this.userName,
      password: this.password
    };
    let checkEmptyFlag = true;
    const checkEmpty = obj => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") checkEmpty(obj[key]);
        // recurse
        else if (obj[key] === null || obj[key] === "") {
          checkEmptyFlag = false;
          return false;
        }
      });
    };
    checkEmpty(data);
    if (checkEmptyFlag) {
      this.pushData(data);
      console.log(checkEmptyFlag);
    } else {
      console.log(checkEmptyFlag);
      var cf = $("#login-form");
      cf.append('<div class="message-container"></div>');
      $(".message-container")
        .html(
          '<div class="alert-box error"><i class="icon-warning"></i><p>Please fill out all fields</p></div>'
        )
        .delay(150)
        .slideDown(300)
        .delay(4000)
        .slideUp(300, function() {
          $("#login-form .message-container").remove();
        });
    }
  }
  ngOnInit() {}
}
