import { Component, OnInit } from "@angular/core";
// import "./../../../assets/plugins/revolution/js/jquery.themepunch.tools.min.js";
// import "./../../../assets/plugins/revolution/js/jquery.themepunch.revolution.min.js";
declare var $: any;

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"]
})
export class SliderComponent implements OnInit {
  constructor() {}

  sliderMain() {
    console.log($("#rev-slider").length);
    ($("#rev-slider") as any).revolution({
      sliderType: "standard",
      spinner: "spinner3",
      responsiveLevels: [4096, 1024, 778, 480],
      delay: 6000,
      navigation: {
        arrows: {
          enable: true,
          left: {
            container: "slider",
            h_align: "left",
            v_align: "center",
            h_offset: 30,
            v_offset: 0
          },
          right: {
            container: "slider",
            h_align: "right",
            v_align: "center",
            h_offset: 30,
            v_offset: 0
          }
        },
        bullets: {
          style: "",
          enable: false,
          container: "slider",
          hide_onmobile: false,
          hide_onleave: false,
          hide_delay: 200,
          hide_under: 0,
          hide_over: 9999,
          tmp: '<span class="circle-bullet"></span>',
          direction: "horisontal",
          space: 10,
          h_align: "center",
          v_align: "bottom",
          v_offset: 20
        }
      },
      gridwidth: 1170,
      gridheight: 730
    });
  }

  ngOnInit() {
    this.sliderMain();
  }
}
