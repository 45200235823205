import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebooklike',
  templateUrl: './facebooklike.component.html',
  styleUrls: ['./facebooklike.component.scss']
})
export class FacebooklikeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
