import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs";
declare var $: JQueryStatic;

@Component({
  selector: "app-enrolnow",
  templateUrl: "./enrolnow.component.html",
  styleUrls: ["./enrolnow.component.scss"]
})
export class EnrolnowComponent implements OnInit {
  branchs = [
    "NRI Layout, Bangalore",
    "Akshaya Nagar, Bangalore",
    "Varanasi, Bangalore",
    "Banaswadi, Bangalore",
    "WCC Road, Nagercoil",
    "Ramavarmapuram, Nagercoil"
  ];
  programs = [
    "2's - Play Group",
    "3's - Nursery",
    "4's - LKG",
    "5's - UKG",
    "Daycare"
  ];
  itemName = "";
  itemMobile = "";
  itemEmail = "";
  itemKidName = "";
  itemDOB = "";
  itemBranch = "";
  itemProgram = "";
  itemMessage = "";
  itemWhen = new Date().toUTCString();
  items: Observable<any[]>;
  enrolmentForm: FormGroup;
  constructor(private fb: FormBuilder, private db: AngularFireDatabase) {
    this.items = db.list("littlebeesorgcontactform").valueChanges();
    // Passing in MD_Bootstrap form validation
    this.enrolmentForm = fb.group({
      contactFormName: ["", Validators.required],
      contactFormMobile: ["", [Validators.required, Validators.email]],
      contactFormEmail: ["", Validators.required],
      contactFormKidName: ["", Validators.required],
      contactFormDoB: ["", Validators.required],
      contactFormBranch: [this.branchs, Validators.required],
      contactFormProgram: [this.programs, Validators.required],
      contactFormMessage: ["", Validators.required]
    });
  }

  clearForm() {
    this.enrolmentForm.reset();
  }
  pushData(data) {
    var cf = $("#enrolment-form");
    cf.append('<div class="message-container"></div>');
    this.db
      .list("/enrolment")
      .push(data)
      .then(snap => {
        const key = snap.key;
        console.log(snap);
        console.log(key);
        this.clearForm();
        $(".message-container")
          .html(
            '<div class="alert-box success"><i class="icon-warning"></i><p> Your message has been sent successfully! </p></div>'
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function () {
            $("#enrolment-form .message-container").remove();
          });
      })
      .catch(err => {
        console.log("Something went wrong:", err.message);
        $(".message-container")
          .html(
            '<div class="alert-box error"><i class="icon-warning"></i><p>' +
            err.message +
            "</p></div>"
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function () {
            $("#enrolment-form .message-container").remove();
          });
      });
  }
  onSubmit() {
    const data = {
      itemName: this.itemName,
      itemMobile: this.itemMobile,
      itemEmail: this.itemEmail,
      itemKidName: this.itemKidName,
      itemDOB: this.itemDOB,
      itemBranch: this.itemBranch,
      itemProgram: this.itemProgram,
      itemMessage: this.itemMessage,
      itemWhen: new Date().toUTCString()
    };
    let checkEmptyFlag = true;
    const checkEmpty = obj => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") checkEmpty(obj[key]);
        // recurse
        else if (obj[key] === null || obj[key] === "") {
          checkEmptyFlag = false;
          return false;
        }
      });
    };
    console.log(data);
    checkEmpty(data);
    if (checkEmptyFlag) {
      this.pushData(data);
    } else {
      var cf = $("#enrolment-form");
      cf.append('<div class="message-container"></div>');
      $(".message-container")
        .html(
          '<div class="alert-box error"><i class="icon-warning"></i><p>Please fill out all fields</p></div>'
        )
        .delay(150)
        .slideDown(300)
        .delay(4000)
        .slideUp(300, function () {
          $("#enrolment-form .message-container").remove();
        });
    }
  }

  ngOnInit() { }
}
