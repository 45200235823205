import { Component, OnInit } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Http, Headers, Response } from "@angular/http";
import { Observable } from "rxjs";
import { Title, Meta } from '@angular/platform-browser';
declare var $: JQueryStatic;

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {
  itemName = "";
  itemMobile = "";
  itemEmail = "";
  itemSubject = "";
  itemMessage = "";
  itemWhen = new Date().toUTCString();
  items: Observable<any[]>;
  contactForm: FormGroup;

  constructor(private fb: FormBuilder, private db: AngularFireDatabase, private titleService: Title, private metaTagService: Meta) {
    this.items = db.list("littlebeesorgcontactform").valueChanges();
    // Passing in MD_Bootstrap form validation
    this.contactForm = fb.group({
      contactFormName: ["", Validators.required],
      contactFormMobile: ["", Validators.required],
      contactFormEmail: ["", [Validators.required, Validators.email]],
      contactFormSubject: ["", Validators.required],
      contactFormMessage: ["", Validators.required]
    });
  }
  pushData(data) {
    var cf = $("#contact-form");
    cf.append('<div class="message-container"></div>');
    this.db
      .list("/contact")
      .push(data)
      .then(snap => {
        const key = snap.key;
        this.clearForm();
        $(".message-container")
          .html(
            '<div class="alert-box success"><i class="icon-warning"></i><p> Your message has been sent successfully! </p></div>'
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function () {
            $("#contact-form .message-container").remove();
          });
      })
      .catch(err => {
        console.log("Something went wrong:", err.message);
        $(".message-container")
          .html(
            '<div class="alert-box error"><i class="icon-warning"></i><p>' +
            err.message +
            "</p></div>"
          )
          .delay(150)
          .slideDown(300)
          .delay(4000)
          .slideUp(300, function () {
            $("#contact-form .message-container").remove();
          });
      });
    //Popup message
    // alert('Thank you for contacting us, your message has gone through!');
  }
  onSubmit() {
    const data = {
      name: this.itemName,
      mobile: this.itemMobile,
      email: this.itemEmail,
      subject: this.itemSubject,
      message: this.itemMessage,
      when: new Date().toUTCString()
    };
    let checkEmptyFlag = true;
    const checkEmpty = obj => {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") checkEmpty(obj[key]);
        // recurse
        else if (obj[key] === null || obj[key] === "") {
          checkEmptyFlag = false;
          return false;
        }
      });
    };
    checkEmpty(data);
    if (checkEmptyFlag) {
      this.pushData(data);
      console.log(checkEmptyFlag);
    } else {
      console.log(checkEmptyFlag);
      var cf = $("#contact-form");
      cf.append('<div class="message-container"></div>');
      $(".message-container")
        .html(
          '<div class="alert-box error"><i class="icon-warning"></i><p>Please fill out all fields</p></div>'
        )
        .delay(150)
        .slideDown(300)
        .delay(4000)
        .slideUp(300, function () {
          $("#contact-form .message-container").remove();
        });
    }
  }

  clearForm() {
    this.contactForm.reset();
  }

  ngOnInit() {
    this.titleService.setTitle("Little Bees International Play School: Contact");
    this.metaTagService.updateTag({ name: 'description', content: "Little Bees International Play School, NRI Layout, Akshaya Nagar, Varanasi, Banaswadi, Bangalore, WCC Road, Ramavarmapuram, Nagercoil" });
  }
}
